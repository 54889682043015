<template>
  <div class="reassign-owner">
    <CyButton
      class="reassign-owner__button"
      theme="grey"
      variant="tertiary"
      :ripple="false"
      v-bind="{
        sm: $attrs.sm,
        lg: $attrs.lg,
      }"
      member-link
      light
      :disabled="$attrs.disabled"
      :readonly="$attrs.readonly"
      @click.native.prevent="openModal">
      <v-icon class="reassign-owner__icon">
        warning
      </v-icon>
      <span class="reassign-owner__button-label">
        {{ $t('noOwner') }}
      </span>
      <span class="reassign-owner__button-label--assign-here">
        {{ $t('assignHere') }}
      </span>
    </CyButton>

    <CyModal
      v-if="showModal"
      :header-title="$t('assignOwnerTitle', { name: _.get(formContent, 'name') })"
      :loading="saving"
      :action-btn-func="submit"
      :action-btn-disabled="!canSave"
      :action-btn-text="$t('assignOwner')"
      :cancel-btn-func="closeModal"
      modal-type="create">
      <CyAlert
        theme="error"
        :content="errors"/>
      <CySelectOwner
        v-model="$v.owner.$model"
        :current-owner="_.get(formContent, 'owner')"/>
    </CyModal>
  </div>
</template>

<script>
import CySelectOwner from '@/components/select-owner.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsAssignOwner',
  components: {
    CySelectOwner,
  },
  props: {
    formContent: {
      type: Object,
      validator: (formContent) => _.$hasAll(formContent, ['owner', 'name']) &&
        _.$hasAny(formContent, ['canonical', 'username', 'id', 'ref']),
      required: true,
    },
    actionBtnFunc: {
      type: Function,
      default: () => {},
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  validations: {
    owner: { required },
  },
  data: () => ({
    formData: null,
    showModal: false,
    saving: false,
    owner: {},
  }),
  computed: {
    canSave () {
      return _.every([
        !_.get(this.owner, 'isMissing'),
        !_.isEmpty(this.owner),
        !this.$v.$invalid,
        !this.saving,
        !_.isEmpty(this.formData),
      ])
    },
  },
  methods: {
    openModal () {
      this.$toggle.showModal(true)
      this.formData = _.cloneDeep(this.formContent)
      this.$setOriginalData()
    },
    async submit () {
      const { owner, formData } = this
      if (!this.canSave) return

      this.$toggle.saving(true)
      await this.actionBtnFunc({ owner, formContent: formData })
      this.$toggle.saving(false)
      if (_.isEmpty(this.errors)) this.closeModal()
    },
    closeModal () {
      this.$v.$reset()
      this.$resetData()
      this.$toggle.showModal(false)
    },
  },
  i18n: {
    messages: {
      en: {
        assignHere: 'assign here',
        assignOwnerTitle: '@:assignOwner to {name}',
      },
      es: {
        assignHere: 'asignar aquí',
        assignOwnerTitle: '@:assignOwner a {name}',
      },
      fr: {
        assignHere: 'attribuer ici',
        assignOwnerTitle: '@:assignOwner à {name}',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.reassign-owner {
  &__icon.v-icon.v-icon:not(.v-btn--disabled, .v-btn--icon) {
    width: 24px;
    height: 24px;
    margin-right: 8px !important;
    margin-left: 0 !important;
    border-radius: 50%;
    background: cy-get-color("warning");
    color: cy-get-color("white") !important;
    font-size: 13.5px;
  }

  &__button-label {
    color: cy-get-color("primary");
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;

    &--assign-here {
      margin-left: 4px;
      color: cy-get-color("grey", "dark-2");
      font-style: italic;
    }
  }
}
</style>
